'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {observer} = require('mobx-react')
const {generateStyleNodeProps} = require('../utils/styleNodeUtils')
const {createCompProps} = require('../utils/propsFetcher')
const {loadFonts} = require('../utils/fontsLoader')

const StyleNode = (props, {componentsModelAspect, getCompClass, rootId, getAspect, fetchSantaType}) => {
    const {id} = props
    const {displayedDAL, pointers} = componentsModelAspect
    const componentPointer = pointers.components.getComponent(id)
    const styleId = displayedDAL.get(pointers.components.getProperty(componentPointer, 'styleId'))

    const componentType = displayedDAL.get(pointers.components.getProperty(componentPointer, 'componentType'))
    const componentClass = getCompClass(componentType)

    if (!componentClass || !componentClass.getCompCss) {
        return null
    }

    const cssProps = createCompProps({fetchSantaType}, componentClass.getCompCss.cssTypes, props)
    const cssData = componentClass.getCompCss(styleId, cssProps)

    const fontsLoaderAspect = componentClass.getCompFonts && getAspect('fontsLoaderAspect')
    if (fontsLoaderAspect) {
        const fontsProps = createCompProps({fetchSantaType}, componentClass.getCompFonts.fontsTypes, props)
        const fontsData = componentClass.getCompFonts(_.keys(cssData), fontsProps)
        loadFonts(fontsLoaderAspect, fontsData)
    }

    return _.map(cssData, (css, innerStyleId) => <style {...generateStyleNodeProps(innerStyleId, css, rootId)}/>)
}

StyleNode.displayName = 'ComponentStyleNode'

StyleNode.propTypes = {
    id: PropTypes.string.isRequired
}

StyleNode.contextTypes = {
    getCompClass: PropTypes.func.isRequired,
    componentsModelAspect: PropTypes.shape({
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired,
    rootId: PropTypes.string,
    getAspect: PropTypes.func,
    fetchSantaType: PropTypes.func
}


module.exports = observer(StyleNode)
