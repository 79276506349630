'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const RootComponent = require('../components/RootComponent')
const ComponentsModelAspect = require('../aspects/ComponentsModelAspect/ComponentsModelAspect')
const BehaviorsAspect = require('../aspects/BehaviorsAspect/BehaviorsAspect')
const defaultPropsFetcher = require('../utils/defaultPropsFetcher')
const privatesMap = new WeakMap()

function fetchSantaTypeImpl(santaTypeDefinition, state, props) {
    const {aspects} = privatesMap.get(this)

    const santaTypeFetcher = this.props.getSantaFetcher(santaTypeDefinition) || // Host fetcher
        _(aspects).invokeMap('getFetcher', santaTypeDefinition).find(Boolean) || // Aspect fetcher
        defaultPropsFetcher.getFetcher(santaTypeDefinition) // Default value

    return santaTypeFetcher(state, props)
}

class Renderer extends React.Component {
    constructor(props) {
        super(props)
        const componentsModelAspect =
            props.aspects.componentsModelAspect || new ComponentsModelAspect(props.componentsModel, props.eventsManager)
        const behaviorsAspect = new BehaviorsAspect(componentsModelAspect, {
            getHandlers: this.getBehaviorsHandlers.bind(this),
            eventsManager: props.eventsManager
        })

        privatesMap.set(this, {
            componentsModelAspect,
            behaviorsAspect,
            aspects: _.defaults({componentsModelAspect, behaviorsAspect}, props.aspects),
            fetchSantaType: fetchSantaTypeImpl.bind(this)
        })
    }

    getBehaviorsHandlers() {
        return this.props._tmp_handlers
    }

    render() {
        return <React.Fragment>
            {_.map(this.props.rootCompsIds, childId => <RootComponent id={childId} key={childId} />)}
        </React.Fragment>
    }

    getChildContext() {
        const {componentsModelAspect, fetchSantaType, aspects} = privatesMap.get(this)
        return {
            rootId: this.props.id,
            componentsModelAspect,
            fetchSantaType,
            getAspect: name => aspects[name],
            getCompClass: this.props.getCompClass,
            onRenderStart: () => {},
            onRenderEnd: () => {},
            onRenderError: () => {},
            registerComponent: () => {}
        }
    }
}

Renderer.defaultProps = {
    getSantaFetcher: _.noop,
    aspects: {},
    _tmp_handlers: {},
    noWrapper: false
}

Renderer.displayName = 'Renderer'

Renderer.propTypes = {
    _tmp_handlers: PropTypes.object,
    noWrapper: PropTypes.bool,
    eventsManager: PropTypes.shape({
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
        emit: PropTypes.func.isRequired
    }).isRequired,
    componentsModel: PropTypes.shape({
        structure: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired
    }),
    getCompClass: PropTypes.func.isRequired,
    getSantaFetcher: PropTypes.func,
    aspects: PropTypes.object,
    rootCompsIds: PropTypes.arrayOf(PropTypes.string.isRequired),
    id: PropTypes.string
}

Renderer.childContextTypes = {
    getCompClass: PropTypes.func.isRequired,
    fetchSantaType: PropTypes.func.isRequired,
    getAspect: PropTypes.func.isRequired,
    onRenderStart: PropTypes.func.isRequired,
    onRenderEnd: PropTypes.func.isRequired,
    onRenderError: PropTypes.func.isRequired,
    registerComponent: PropTypes.func.isRequired,
    componentsModelAspect: PropTypes.shape({
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired,
    rootId: PropTypes.string
}

module.exports = Renderer
