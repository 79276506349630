'use strict'

const _ = require('lodash')
const pointers = require('./pointers')
const runtimeUtil = require('../../utils/runtime')
const constants = require('../../utils/constants')

const {
    DATA_QUERY,
    PROPERTY_QUERY,
    DESIGN_QUERY,
    STYLE_ID
} = constants.pointers.components.PROPERTY_TYPES

const {
    DATA,
    DESIGN,
    PROPERTIES,
    STYLE
} = constants.pointers.data.DATA_MAPS

module.exports = {
    create: (model, displayedDAL, schemas, events) => {
        const getQuery = (compId, queryName) => {
            const compPointer = pointers.components.getComponent(compId)
            const compDataPointer = pointers.components.getProperty(compPointer, queryName)

            const originalQuery = displayedDAL.get(compDataPointer)
            return _.replace(originalQuery, '#', '')
        }

        const setRefDataOverride = (compId, dataId, mapName, partialOverrides) => {
            const runtimeId = runtimeUtil.getRuntimeId(compId, dataId.replace('#', ''))
            const currentOverrides = model.runtime.data[mapName][runtimeId]

            model.updateDataOverrides(mapName, runtimeId, _.assign({}, currentOverrides, partialOverrides))

            return runtimeId
        }

        const setData = (compId, queryName, mapName, partialOverrides) => {
            const query = getQuery(compId, queryName)
            const compData = model.data[mapName][query] || {}
            const dataOverrides = _.mapValues(partialOverrides,
                (partialValue, key) => _.get(schemas, [compData.type, key]) ?
                    setRefDataOverride(compId, compData[key], mapName, partialValue) :
                    partialValue
            )

            const runtimeId = runtimeUtil.getRuntimeId(compId, query)
            const currentOverrides = model.runtime.data[mapName][runtimeId]

            model.updateDataOverrides(mapName, runtimeId, _.assign({}, currentOverrides, dataOverrides))
        }

        const getData = (compId, queryName, mapName) => {
            const query = getQuery(compId, queryName)

            return model.resolved[mapName][query]
        }

        return {
            getCompData: compId => getData(compId, DATA_QUERY, DATA),
            getCompProps: compId => getData(compId, PROPERTY_QUERY, PROPERTIES),
            getPlatformModels: contextId => _.cloneDeep(model.RMI[contextId]),
            getPopupContext: _.noop,
            registerComponentEvent: (compId, newEventData) => {
                events.emit(constants.EVENTS.REGISTER_COMPONENT, {compId, newEventData})
            },
            reset: () => model.setRuntime(runtimeUtil.createEmptyRuntime()),
            setCompData: (compId, partialOverrides) => setData(compId, DATA_QUERY, DATA, partialOverrides),
            setCompDesign: (compId, partialOverrides) => setData(compId, DESIGN_QUERY, DESIGN, partialOverrides),
            setCompProps: (compId, partialOverrides) => setData(compId, PROPERTY_QUERY, PROPERTIES, partialOverrides),
            updateContext: (contextId, rootIds) => {
                model.updateContext(contextId, rootIds)
            },
            updateCompLayout: _.noop,
            updateCompStyle: (compId, partialOverrides) => setData(compId, STYLE_ID, STYLE, partialOverrides)
        }
    }
}
