'use strict'

const _ = require('lodash')
const runtimeUtil = require('../../../utils/runtime')
const StructureNode = require('../../../utils/StructureNode')
const constants = require('../../../utils/constants')

const isRef = (schema, key) => schema && schema[key] === 'ref'

const create = (eventsManager, schemas, mobxModel) => ({
    isRef: (value, key, type) => _.isString(value) && isRef(schemas[type], key),
    isRefList: (value, key, type) => _.isArray(value) && isRef(schemas[type], key),
    removeHash: value => value.replace('#', ''),
    isRuntimeId: id => runtimeUtil.isRuntimeId(id),
    getRuntimeId: (compId, dataId) => runtimeUtil.getRuntimeId(compId, dataId),
    getCompIdFromRuntimeId: runtimeId => runtimeUtil.getCompId(runtimeId),
    getQueryIdFromRuntimeId: runtimeId => runtimeUtil.getQueryId(runtimeId),
    groupByCompId: arr => _.groupBy(arr, 'compId'),
    parseJSON: val => JSON.parse(val),
    updateRuntimeActionsMobxData: (dataItem, id) => {
        if (id && mobxModel) {
            mobxModel.runtimeComponentActions.set(id, _.clone(dataItem))
        }

        return dataItem
    },
    updateMobxData: mobxModel ? (dataItem, id, map) => {
        mobxModel.data[map].set(id, _.clone(dataItem))
        return dataItem
    } : _.identity,
    updateOnRuntimeCompDataChange: (partialData, compId, changeObject) => {
        eventsManager.emit(constants.EVENTS.COMP_DATA_RUNTIME_CHANGE, compId, changeObject)

        return partialData
    },
    generateActionBehaviorsEvents: ([statics, runtime]) =>
        _([statics, runtime])
            .flatMap(behaviorsForComponentAction => _.flatMap(behaviorsForComponentAction, (compActions, compId) =>
                _.flatMap(compActions, (behaviors, eventType) =>
                    _.map(behaviors, behavior =>
                        ({compId, eventType, ...behavior.params})
                    )
                )
            ))
            .groupBy('compId')
            .value(),
    updateMobxStructure: mobxModel ? structure => {
        const current = mobxModel.structure.get(structure.id)
        if (current) {
            _.assign(current, structure)
        } else {
            mobxModel.structure.set(structure.id, new StructureNode(structure))
        }
        return structure
    } : _.identity
})


module.exports = {
    create
}
