'use strict'
const storageAPI = require('./utils/storageAPI')
const Renderer = require('./components/Renderer')
const IsolatedRenderer = require('./components/IsolatedRenderer')
const ComponentsModelAspect = require('./aspects/ComponentsModelAspect/ComponentsModelAspect')
const BehaviorsAspect = require('./aspects/BehaviorsAspect/BehaviorsAspect')

module.exports = {
    utils: {
        storageAPI,
        ComponentsModelAspect,
        BehaviorsAspect
    },
    Renderer,
    IsolatedRenderer
}
