'use strict'

const createComponentPropsFetchers = require('./componentPropsFetchers')
const getBehaviorsForAction = require('./getBehaviorsForAction')
const constants = require('../../utils/constants')

const PRIVATES_MAP = new WeakMap()

class BehaviorsAspect {
    constructor(componentsModelAspect, {getHandlers, eventsManager}) {
        const {mobxModel} = componentsModelAspect

        eventsManager.on(constants.EVENTS.REGISTER_COMPONENT, value => this.registerComponentEvent(value))
        this.getFetcher = createComponentPropsFetchers({storage: mobxModel.runtimeComponentActions, behaviorsAspect: this})

        PRIVATES_MAP.set(this, {
            getHandlers,
            $model: componentsModelAspect.carmiModel,
            mobxModel
        })
    }

    registerComponentEvent({compId, newEventData}) {
        const action = {
            type: 'comp',
            name: newEventData.eventType,
            sourceId: compId
        }
        const behavior = {
            name: 'runCode',
            type: 'widget',
            targetId: newEventData.contextId,
            params: {
                callbackId: newEventData.callbackId,
                compId
            }
        }
        const {$model} = PRIVATES_MAP.get(this)
        $model.spliceRuntimeBehavior($model.runtimeActionBehaviors.length, 0, {action, behavior, compId})
    }

    _getBehaviorsForAction(action) {
        const {$model} = PRIVATES_MAP.get(this)
        return getBehaviorsForAction({action, $model})
    }

    handleAction(componentId, action) {
        const behaviors = this._getBehaviorsForAction(action)

        if (behaviors.length === 0) {
            return 0
        }

        const handlers = PRIVATES_MAP.get(this).getHandlers()
        let result = 0
        behaviors.forEach(behavior => {
            const handler = handlers[behavior.type]
            if (!handler) {
                console.warn(`There is no handler defined for type '${behavior.type}'. Nothing to do here!`)
                return
            } else if (typeof handler !== 'function') {
                console.warn(`Provided handler for '${behavior.type}' is not a function. Aborting!`)
                return
            }

            result++
            handlers[behavior.type](behavior, {action, componentId})
        })

        return result
    }
}

module.exports = BehaviorsAspect
