'use strict'

const _ = require('lodash')
const constants = require('../../utils/constants')

const {DATA_MAPS} = constants.pointers.data
const {PROPERTY_TYPES} = constants.pointers.components

const COMPONENT_POINTER_TYPE = 'COMPONENT'
const THEME_DATA_POINTER_ID = 'THEME_DATA'

const DATA_POINTER_TYPES = _.mapValues(DATA_MAPS, (v, type) => type)
const COMPONENT_PROPERTY_POINTER_TYPES = _.invert(PROPERTY_TYPES)

const createTypedPointer = type => id => ({id: id.replace('#', ''), type})

module.exports = {
    components: {
        getComponent: createTypedPointer(COMPONENT_POINTER_TYPE),
        getProperty: (pointer, property) => {
            const type = COMPONENT_PROPERTY_POINTER_TYPES[property]
            if (pointer.type !== COMPONENT_POINTER_TYPE || !type) {
                throw new Error('pointer must be a component pointer or invalid property name')
            }

            return {id: pointer.id, type}
        }
    },
    data: {
        getBehaviorsPointer: createTypedPointer(DATA_POINTER_TYPES.BEHAVIORS),
        getConnectionsPointer: createTypedPointer(DATA_POINTER_TYPES.CONNECTIONS),
        getDataPointer: createTypedPointer(DATA_POINTER_TYPES.DATA),
        getDesignPointer: createTypedPointer(DATA_POINTER_TYPES.DESIGN),
        getMobileHintsPointer: createTypedPointer(DATA_POINTER_TYPES.MOBILE_HINTS),
        getPropertiesPointer: createTypedPointer(DATA_POINTER_TYPES.PROPERTIES),
        getStylePointer: createTypedPointer(DATA_POINTER_TYPES.STYLE),
        getThemeDataPointer: () => createTypedPointer(DATA_POINTER_TYPES.STYLE)(THEME_DATA_POINTER_ID)
    },
    getPath: ({id, type}) => {
        if (type === COMPONENT_POINTER_TYPE) {
            return ['structure', id]
        }

        const property = _.get(PROPERTY_TYPES, type)
        if (property) {
            return ['structure', id, property]
        }

        const dataMap = _.get(DATA_MAPS, type)
        if (dataMap) {
            return ['data', dataMap, id]
        }

        throw new Error(`Invalid pointer type: ${type}`)
    }
}
