'use strict'

const _ = require('lodash')

const loadFonts = (fontsLoaderAspect, fontsData) => {
    const unhandledFontsList = fontsLoaderAspect.filterHandledFonts && fontsLoaderAspect.filterHandledFonts(fontsData)

    if (!_.isEmpty(unhandledFontsList)) {
        fontsLoaderAspect.loadFonts(unhandledFontsList)
    }
}

module.exports = {loadFonts}