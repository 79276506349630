'use strict'

const _ = require('lodash')
const mobx = require('mobx')
const constants = require('./constants')

const {PROPERTY_TYPES} = constants.pointers.components
const privatesMap = new WeakMap()

function addObservableArrayProperty(propertyName) {
    Object.defineProperty(
        StructureNode.prototype,
        propertyName,
        {
            get() {
                return privatesMap.get(this)[propertyName]
            },
            set(val) {
                const privates = privatesMap.get(this)
                if (!privates[propertyName]) {
                    privates[propertyName] = mobx.observable.shallowArray(
                        _.clone(val),
                        `StructureNode.${this.id}.${propertyName}`
                    )
                } else if (val) {
                    const prop = privates[propertyName]
                    if (!_.isEqual(prop.slice(), val)) {
                        prop.replace(_.clone(val))
                    }
                }
            },
            enumerable: true
        })
}

function addSimpleObservableProperty(propertyName) {
    Object.defineProperty(
        StructureNode.prototype,
        propertyName,
        {
            get() {
                const privates = privatesMap.get(this)
                privates[propertyName] = privates[propertyName]
                return privates[propertyName].get()
            },
            set(val) {
                const privates = privatesMap.get(this)
                if (!privates[propertyName]) {
                    privates[propertyName] = mobx.observable.shallowBox(val, `StructureNode.${this.id}.${propertyName}`)
                } else {
                    privates[propertyName].set(val)
                }
            },
            enumerable: true
        })
}

function addObjectObservableProperty(propertyName) {
    Object.defineProperty(
        StructureNode.prototype,
        propertyName,
        {
            get() {
                const privates = privatesMap.get(this)
                privates[propertyName] = privates[propertyName]
                return privates[propertyName].get()
            },
            set(val) {
                const privates = privatesMap.get(this)
                if (!privates[propertyName]) {
                    privates[propertyName] = mobx.observable.shallowBox(
                        _.clone(val),
                        `StructureNode.${this.id}.${propertyName}`
                    )
                } else {
                    const prop = privates[propertyName]
                    if (!_.isEqual(prop.get(), val)) {
                        prop.set(_.clone(val))
                    }
                }
            },
            enumerable: true
        })
}

function StructureNode(structure) {
    this.id = structure.id
    privatesMap.set(this, {})
    _.forOwn(structure, (value, property) => {
        this[property] = value
    })
}

addSimpleObservableProperty(PROPERTY_TYPES.DATA_QUERY)
addSimpleObservableProperty(PROPERTY_TYPES.PROPERTY_QUERY)
addSimpleObservableProperty(PROPERTY_TYPES.DESIGN_QUERY)
addSimpleObservableProperty(PROPERTY_TYPES.BEHAVIOR_QUERY)
addSimpleObservableProperty(PROPERTY_TYPES.CONNECTION_QUERY)
addSimpleObservableProperty(PROPERTY_TYPES.COMPONENT_TYPE)
addSimpleObservableProperty(PROPERTY_TYPES.SKIN)
addSimpleObservableProperty(PROPERTY_TYPES.STYLE_ID)
addSimpleObservableProperty(PROPERTY_TYPES.PARENT)

addObjectObservableProperty(PROPERTY_TYPES.LAYOUT)
addObjectObservableProperty(PROPERTY_TYPES.MODES)

addObservableArrayProperty(PROPERTY_TYPES.COMPONENTS)

Object.defineProperty(StructureNode.prototype, 'isStructureNode', {enumerable: false, value: true})

module.exports = StructureNode
