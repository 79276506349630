'use strict'

const _ = require('lodash')

const getSantaTypes = _.memoize(propTypes => _.pickBy(propTypes, p => p.id || p.fetch))

const createState = context => ({
    fetchSantaType: context.fetchSantaType
})

const createCompProps = (context, propTypes, ownProps) => {
    const fetcherState = createState(context)

    if (!propTypes) {
        return _.clone(ownProps)
    }

    return _(propTypes)
        .thru(getSantaTypes)
        .mapValues(santaTypeDefinition =>
            context.fetchSantaType(
                santaTypeDefinition,
                fetcherState,
                ownProps
            )
        )
        .defaults(ownProps)
        .value()
}

module.exports = {createCompProps}